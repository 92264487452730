@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

html {
  --bg-color: #fff;
  --input-bg-color: #f0f0f0;
  --text-color: #212121;
  --text-hidden: #aaa;
  --text-disconnected: #888;
  --text-correct: #388e3c;
  --text-wrong: #d32f2f;
  --button-color: #26a69a;
  --button-highlight: #2bbbad;
  --button-gray: #888;
  --button-gray-highlight: #999;
}

html.dark {
  --bg-color: #000;
  --input-bg-color: #222;
  --text-color: #fff;
  --text-hidden: #aaa;
  --text-disconnected: #888;
  --text-correct: #57bf5b;
  --text-wrong: #d32f2f;
  --button-color: #81e6dd;
  --button-highlight: #5cada6;
  --button-gray: #888;
  --button-gray-highlight: #999;
}

@media all and (min-width: 760px) {
  body {
    font-size: 23px;
  }
}
@media all and (max-width: 760px) {
  body {
    font-size: 22px;
  }
}
@media all and (max-width: 720px) {
  body {
    font-size: 21px;
  }
}
@media all and (max-width: 680px) {
  body {
    font-size: 20px;
  }
}
@media all and (max-width: 640px) {
  body {
    font-size: 19px;
  }
}
@media all and (max-width: 600px) {
  body {
    font-size: 18px;
  }
}
@media all and (max-width: 560px) {
  body {
    font-size: 17px;
  }
}
@media all and (max-width: 520px) {
  body {
    font-size: 16px;
  }
}
@media all and (max-width: 400px) {
  body {
    font-size: 15px;
  }
}
@media all and (max-width: 360px) {
  body {
    font-size: 14px;
  }
}
@media all and (max-width: 330px) {
  body {
    font-size: 13px;
  }
}
@media all and (max-width: 300px) {
  body {
    font-size: 12px;
  }
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.App-Wrapper {
  font-family: "Lato", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    "Verdana", sans-serif;
  margin: 0 auto;
  max-width: 40em;
}

button {
  background-color: var(--button-color);
  border-radius: 2px;
  border: none;
  color: var(--bg-color);
  cursor: pointer;
  font: inherit;
  height: 2em;
  margin: 0 0.25em;
  padding: 0 1em 0.25em;
  text-align: center;
  text-decoration: none;
  transition: 0.3s background-color;
  vertical-align: middle;
}

button:hover {
  background-color: var(--button-highlight);
  transition: 0.3s background-color;
}

button.small {
  font-size: 0.8em;
  height: 1.5em;
  padding: 0 0.75em 0.25em;
}

button.gray {
  background-color: var(--button-gray);
}

button.gray:hover {
  background-color: var(--button-gray-highlight);
}

input,
select,
textarea {
  background: var(--input-bg-color);
  color: var(--text-color);
  border: none;
  border-bottom: 2px solid var(--input-bg-color);
  border-radius: 0;
  box-sizing: border-box;
  font: inherit;
  height: 2em;
  margin: 0 0.25em;
  padding: 0.25em;
  transition: 0.3s border-bottom;
  vertical-align: middle;
  width: 10em;
}

textarea {
  height: 5em;
  width: 100%;
}

input:focus,
select:focus {
  border-bottom: 2px solid var(--button-highlight);
  outline: none;
  transition: 0.3s border-bottom;
}

a {
  border-bottom: 2px solid var(--bg-color);
  box-sizing: border-box;
  color: var(--button-color);
  text-decoration: none;
  transition: 0.3s border-bottom;
}

a:hover {
  border-bottom: 2px solid var(--button-color);
  transition: 0.3s border-bottom;
}
