.Players-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Players-Table {
  border: none;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}

.Players-Table td {
  overflow: hidden;
  padding: 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Players-Table tbody tr:nth-child(2n + 1) {
  background-color: var(--input-bg-color);
}

.Players-Table tr td:last-child {
  width: 60%;
}

.Players-Toggle {
  float: right;
}

.Players-ToggledOffToggle {
  background-color: var(--button-gray);
}

.Players-ToggledOffToggle:hover {
  background-color: var(--button-gray-highlight);
}

.Players-Name {
  vertical-align: middle;
}

.toggledOff {
  text-decoration: line-through;
}

.guessing,
.hidden,
.notSubmitted {
  color: var(--text-hidden);
}

.disconnected {
  color: var(--text-disconnected);
}

.disconnected-marker {
  font-size: 0.8em;
}
