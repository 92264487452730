.Room-Wrapper {
  padding-bottom: 5em;
}

.Round-Status {
  color: var(--text-disconnected);
  margin-top: 1em;
  padding: 0.5em;
}

.Round-Status b {
  color: var(--text-color);
  font-weight: normal;
}

.Round-Status.old {
  margin-top: 2em;
}

.correct {
  color: #388e3c;
}

.wrong {
  color: #d32f2f;
}
