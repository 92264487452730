.NavBar-Wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}

.NavBar-Home {
  font-weight: bold;
}

.NavBar-Rules {
  margin-left: 1em;
}
